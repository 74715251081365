<template>
  <form class="form-auth form-base row" @submit="formSubmit"  @submit.prevent="formSubmit">
    <div class="col-12">
      <FveEmail
        label="E-mail"
        name="email"
        required
        :disabled="isEmail"
        v-model="form.email"
        @keypress-enter="formSubmit"
      />
    </div>
    <div class="col-6">
      <template v-if="isEmail">
        <FveText
          name="code"
          placeholder="Введите код"
          required
          v-model="form.code"
          @keypress-enter="formSubmit"
        />
      </template>
    </div>
  </form>
</template>

<script>

import FveEmail from "@widgetFormValidate/src/Element/Text/FveEmail";
import FveText from "@widgetFormValidate/src/Element/Text/FveText";
//
import FveFormMixin from "@widgetFormValidate/src/Mixin/FveFormMixin";

export default {
  mixins: [
    FveFormMixin
  ],
  components: {
    FveEmail,
    FveText,
  },
  props: {
    isEmail: Boolean,
  },
  methods: {
    formSchema() {
      return {
        email: {
          type: String,
          default: () => { return ''; }
        },
        code: {
          type: [String, Number],
          default: () => { return ''; }
        },
      };
    },
  }

};

</script>

<style lang="scss" scoped>
</style>
