<template>
  <div class="auth">
    <h1 class="auth__title">Восстановление пароля</h1>
    <p class="auth__text">Пожалуйста, укажите email</p>
    <RecoveryForm ref="recoveryForm" :isEmail="isEmail"/>
    <div class="auth__btn">
      <template v-if="isEmail">
        <b-button block variant="danger" size="lg" @click="verify">Войти</b-button>
      </template>
      <template v-else>
        <b-button block variant="danger" size="lg" @click="restore">Выслать пароль</b-button>
      </template>
      <router-link :to="{ name: this.$routeName.AUTH }" class="auth__link">Вернуться к форме входа</router-link>
    </div>
  </div>
</template>

<script>

import RecoveryForm from "@component/Form/RecoveryForm";

export default {
  name: "Recovery",
  components: {
    RecoveryForm,
  },
  data() {
    return {
      isEmail: false,
    };
  },
  methods: {
    async restore() {
      let recoveryFormData = this.$refs.recoveryForm.formSubmitGetData();
      if(!recoveryFormData) { return; }
  
      RequestManager.Auth.restore({
        email: recoveryFormData.email,
      }).then( (res) => {
        if(res) {
          this.$dialogs.alert(res.detail, {size: 'sm'});
        }
        this.isEmail = true;
      });
    },
    async verify() {
      let recoveryFormData = this.$refs.recoveryForm.formSubmitGetData();
      if(!recoveryFormData) { return; }
  
      RequestManager.Auth.verify({
        email: recoveryFormData.email,
        token: recoveryFormData.code,
      }).then( (res) => {
        if(!res.token) {
          this.$dialogs.alert('Не удалось авторизоваться', {title: 'Ошибка', size: 'sm'});
        }
  
        this.$store.commit('user/setToken', res.token);
  
        RequestManager.Admin.getProfileMe({}).then((data) => {
          let role = '';
          if(data.system_role === 'admin') {
            role = 'ROLE_SUPER_ADMIN';
          }
          this.$store.commit('user/setRole', role);
        });
  
        this.$nextTick(() => {
          this.$router.push({name: this.$routeName.PROFILE});
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>

// auth
.auth {
  width: 100%;
  max-width: 442px;
  .auth__title {
    margin-bottom: 26px;
    font-size: 25px;
  }
  .auth__text {
    margin-bottom: 26px;
    color: var(--text-descr);
    font-size: 17px;
  }
  .auth__btn {
    display: flex;
    align-items: center;
    margin-top: 10px;
    text-align: center;
    .btn {
      max-width: 200px;
    }
    .auth__link {
      margin-left: 20px;
      color: var(--color-sub);
      font-weight: 500;
      font-size: 17px;
      &:hover {
        color: var(--color-sub-active);
      }
    }
  }
}
</style>
